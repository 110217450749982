/* eslint-disable import/prefer-default-export */

import React from 'react';
import Provider from './src/context';

export const wrapPageElement = ({ element }, {
  maxTimesModalOpenedAllowed,
}) => {
  if ([undefined, null].includes(typeof maxTimesModalOpenedAllowed)) {
    throw new Error('missing maxTimesModalOpenedAllowed in times-modal-opened gatsby-ssr');
  }
  return (
    <Provider maxTimesModalOpenedAllowed={maxTimesModalOpenedAllowed}>
      {element}
    </Provider>
  );
};
