/* eslint-disable import/prefer-default-export */

import React from 'react';
import ModalsContextProvider from './src/context/modals';

export const wrapPageElement = ({
  element,
}) => (
  <ModalsContextProvider>
    {element}
  </ModalsContextProvider>
);
