/* eslint-disable import/prefer-default-export */

import sbjs from 'sourcebuster';

const ENV_CONTEXT = process.env.CONTEXT;
const CONTEXT_ENV = process.env.GATSBY_ENV || ENV_CONTEXT;
const production = CONTEXT_ENV === 'production';

export const wrapPageElement = ({ element }) => {
  const callback = () => console.log('sourcebuster init.', sbjs.get);
  sbjs.init({
    ...(production ? {} : { callback }),
    campaign_param: 'campaign',
  });
  const browser = typeof window !== 'undefined';
  if (browser) {
    window.sbjs = sbjs;
  }
  return element;
};

export const onRouteUpdate = () => {
  if (!production) {
    return;
  }
  // adcalls needs a trigger to re-eval the page for phonenumbers
  if (typeof acalltracker !== 'undefined') {
    acalltracker.dynamicPageload(); // eslint-disable-line no-undef
  }
  // if there is a phonenumber in changing content, trigger:
  // if (typeof acalltracker !== 'undefined'){acalltracker.dynamicContentload();}
};
