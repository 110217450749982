import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import useContactPageSlug from '../hooks/use-contact-page-slug';

const MessageBubble = createContext(null);
export const useMessageBubble = () => useContext(MessageBubble);

export const Provider = ({ children, pageContext }) => {
  const [isEnabled, setEnabled] = useState(false);
  const [isHidden, setHidden] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const timesAutoOpenedRef = useRef(0);
  const contactPageSlug = useContactPageSlug();
  const { pathname, href } = useLocation();
  const [defaultTitle, setDefaultTitle] = useState('Contact');
  const [title, setTitle] = useState(defaultTitle);
  // used for things like the text sent in a whatsapp message
  const [defaultText, setDefaultText] = useState('Ik heb een vraag via ');
  const defaultTextWithHref = `${defaultText} ${href}`;
  const [text, setText] = useState(defaultTextWithHref);

  const [autoOpenOnContactPage, setAutoOpenOnContactPage] = useState(false);
  const [autoOpenAfterMsArray, setAutoOpenAfterMsArray] = useState(null);
  const [autoOpenAfterMsPathnameList, setAutoOpenAfterMsPathnameList] = useState(null);
  const [hideOnVDP, setHideOnVDP] = useState(null);
  const { vdpType } = pageContext; // we hard assume vdpType is set by a vdp

  // everytime, check if page is a vdp, if so: hide.
  useEffect(() => {
    if (hideOnVDP) {
      if (vdpType) {
        setHidden(true);
      } else {
        setHidden(false);
      }
    }
  }, [
    hideOnVDP,
    vdpType,
  ]);

  // everytime, check if pathname is contact. If so, auto open.
  useEffect(() => {
    if (autoOpenOnContactPage && pathname === contactPageSlug) {
      setOpen(true);
    }
  }, [
    autoOpenOnContactPage,
    pathname,
    contactPageSlug,
  ]);

  // autoOpenAfterMsArray is an array of ms to use between auto opens
  useEffect(() => {
    if (!(
      autoOpenAfterMsArray
      && autoOpenAfterMsArray.length > 0
      && autoOpenAfterMsArray[timesAutoOpenedRef.current]
    )) {
      return undefined;
    }
    if (
      autoOpenAfterMsPathnameList
      && autoOpenAfterMsPathnameList.length > 0
      && !autoOpenAfterMsPathnameList.includes(pathname)
    ) {
      return undefined;
    }
    const timeout = setTimeout(() => {
      setOpen(true);
      timesAutoOpenedRef.current += 1;
    }, autoOpenAfterMsArray[timesAutoOpenedRef.current]);
    return () => clearTimeout(timeout);
  }, [
    autoOpenAfterMsArray,
    autoOpenAfterMsPathnameList,
    pathname,
  ]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  // you could have setTitle and setOpen with a different message,
  // so use this function to show the default state of the button
  const onResetOpen = useCallback(() => {
    setTitle(defaultTitle);
    setText(defaultTextWithHref);
    setOpen(true);
  }, [
    setTitle,
    setText,
    setOpen,
    defaultTitle,
    defaultTextWithHref,
  ]);

  const value = useMemo(() => ({
    isEnabled,
    isHidden,
    isOpen,
    setOpen,
    onClose,
    onResetOpen,
    title,
    setTitle,
    text,
    setText,

    // initials, used by the provider component
    setEnabled,
    setDefaultTitle,
    setDefaultText,
    setAutoOpenOnContactPage,
    setAutoOpenAfterMsArray,
    setAutoOpenAfterMsPathnameList,
    setHideOnVDP,
  }), [
    isEnabled,
    isHidden,
    isOpen,
    setOpen,
    onClose,
    onResetOpen,
    title,
    setTitle,
    text,
    setText,

    // initials, used by the provider component
    setEnabled,
    setDefaultTitle,
    setDefaultText,
    setAutoOpenOnContactPage,
    setAutoOpenAfterMsArray,
    setAutoOpenAfterMsPathnameList,
    setHideOnVDP,
  ]);

  return (
    <MessageBubble.Provider
      value={value}
    >
      {children}
    </MessageBubble.Provider>
  );
};
