/* eslint-disable import/prefer-default-export */

import React from 'react';
import { Provider } from './src/context/messagebubble';

export const wrapPageElement = ({
  element,
  props: { pageContext },
}) => (
  <Provider pageContext={pageContext}>
    {element}
  </Provider>
);
