import { useStaticQuery, graphql } from 'gatsby';

const useContactPageSlug = () => {
  const { page } = useStaticQuery(graphql`
    query Strapi4MessagebubbleUseContactPageSlug {
      page: allStrapiPage(
        filter: {
          template: {
            in: ["contact"]
          }
        }
      ) {
        nodes {
          slug
        }
      }
    }
  `);

  const slug = page?.nodes[0]?.slug;
  if (!slug) {
    return null;
  }

  return slug.endsWith('/') ? slug : `${slug}/`;
};

export default useContactPageSlug;
