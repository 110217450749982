/* eslint-disable import/prefer-default-export */

import React from 'react';
import NavProvider from './src/components/nav/provider';

export const wrapPageElement = ({
  element,
}) => (
  <NavProvider>
    {element}
  </NavProvider>
);
