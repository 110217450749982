import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const Context = createContext();

export const useTimesModalOpened = () => useContext(Context);

const ContextProvider = ({
  children,
  maxTimesModalOpenedAllowed,
}) => {
  const [timesModalOpened, setTimesModalOpened] = useState(0);
  useEffect(() => {
    let storage;
    try {
      storage = sessionStorage.getItem('__times-modal-opened');
    } catch {
      return undefined;
    }
    setTimesModalOpened(Number(storage || 0));
    return undefined;
  }, []);

  // change modalAllowedToOpen to false when 2 < 2,
  // so next time we get a no.
  const modalAllowedToOpen = timesModalOpened < maxTimesModalOpenedAllowed;

  const addTimesModalOpened = useCallback(() => {
    setTimesModalOpened((state) => {
      const newState = state + 1;
      try {
        sessionStorage.setItem('__times-modal-opened', newState);
      } catch {
        // do nothing
      }
      return newState;
    });
  }, []);

  const value = useMemo(() => ({
    timesModalOpened,
    modalAllowedToOpen,
    addTimesModalOpened,
  }), [
    timesModalOpened,
    modalAllowedToOpen,
    addTimesModalOpened,
  ]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
