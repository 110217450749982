import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';

const ModalsContext = createContext();

export const useModals = () => useContext(ModalsContext);

const ModalsContextProvider = ({
  children,
}) => {
  const [modals, setModals] = useState({});

  const addModal = useCallback((identifier) => {
    setModals((state) => ({
      ...state,
      [identifier]: false,
    }));
  }, []);

  const setModalOpen = useCallback((identifier, open) => {
    setModals((state) => {
      // we dont allow opening a modal when there is already a modal open
      if (open === true && Object.values(state).some((isOpen) => isOpen === true)) {
        return state;
      }
      return {
        ...state,
        [identifier]: open,
      };
    });
  }, []);

  const isModalOpen = useCallback((identifier) => Boolean(modals[identifier]), [modals]);

  const identifierIsModal = useCallback((identifier) => Object.keys(modals)
    .includes(identifier), [modals]);

  const value = useMemo(() => ({
    addModal,
    setModalOpen,
    isModalOpen,
    identifierIsModal,
  }), [
    addModal,
    setModalOpen,
    isModalOpen,
    identifierIsModal,
  ]);

  return (
    <ModalsContext.Provider value={value}>
      {children}
    </ModalsContext.Provider>
  );
};

export default ModalsContextProvider;
