import React from 'react';
import ErrorBoundary from 'gatsby-theme-core/src/components/error-boundary';

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({
  element,
}) => (
  <ErrorBoundary>
    {element}
  </ErrorBoundary>
);
