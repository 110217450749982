/* eslint-disable import/prefer-default-export */

import React from 'react';
import { Script } from 'gatsby';

export const wrapPageElement = ({ element }, {
  assistantId,
  accessToken,
}) => {
  if (!assistantId) {
    throw new Error('missing assistantId in autochat gatsby-ssr');
  }
  if (!accessToken) {
    throw new Error('missing accessToken in autochat gatsby-ssr');
  }
  return (
    <>
      {element}
      <Script
        src="https://static.chatclient.autochat.ai/release/autochat.js?ver=1"
        onLoad={() => {
          console.log('[autochat] onLoad, init Autochat');
          // eslint-disable-next-line no-undef
          autochat.init({
            assistantId,
            accessToken,
          });
          // eslint-disable-next-line no-undef
          // getAutochat().then((autochat) => {
          // });
        }}
        onError={() => console.error(new Error('Failed to load autochat script'))}
      />
      <div id="autochat-root" />
      <div id="autochat-chat-overlay" />
    </>
  );
};
