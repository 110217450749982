import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useLocation } from '@gatsbyjs/reach-router';

const NavContext = createContext();

export const useNav = () => useContext(NavContext);

const NavProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    // always close the menu when a pathname changes
    setOpen(false);
  }, [pathname]);

  const value = useMemo(() => ({ open, setOpen }), [open, setOpen]);

  return (
    <NavContext.Provider value={value}>
      {children}
    </NavContext.Provider>
  );
};

export default NavProvider;
