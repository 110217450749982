import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import fetch from 'cross-fetch';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import authLink from './auth-link'; // gatsby-theme-user adds bearer token

if (!process.env.GATSBY_API_URL) {
  throw new Error('Missing process.env.GATSBY_API_URL');
}

// Instantiate required constructor fields
const cache = new InMemoryCache();
const link = createUploadLink({
  uri: `${process.env.GATSBY_API_URL}/graphql`,
  fetch,
});
const client = new ApolloClient({
  // Provide required constructor fields
  cache,
  link: from([authLink || null, link].filter(Boolean)),
});

export default client;
